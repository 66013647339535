import ServiceImpl from '@/service/ServiceImpl'

class SliceService extends ServiceImpl {
  prefix = '/customize/slice'

  constructor () {
    super()
    if (!SliceService.instance) {
      SliceService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return SliceService.instance
  }
}

class SloganService extends ServiceImpl {
  prefix = '/customize/slogan'

  constructor () {
    super()
    if (!SloganService.instance) {
      SloganService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return SloganService.instance
  }
}

export {
  SliceService,
  SloganService
}
