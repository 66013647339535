import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import '@public/static/iconfont/iconfont.css'
import '@public/static/css/font.css'
import '@/element/index'
import 'element-ui/lib/theme-chalk/index.css'
import httpRequest from '@/utils/httpRequest'

Vue.config.productionTip = false

Vue.use(VueViewer)

// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法

Vue.prototype.$bus = new Vue()

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
