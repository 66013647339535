export default class LayoutEnum {
  static INDEX = 'INDEX'
  static LAYER = 'LAYER'
  static SLOGAN = 'SLOGAN'
  static ADDRESS = 'ADDRESS'
  static DIVISION = 'DIVISION'
  static CATEGORY = 'CATEGORY'
  static COVER = 'COVER'
  static IMAGE = 'IMAGE'
  static COMPONENT = 'COMPONENT'
  static SPECIES = 'SPECIES'
  static QUALIFY = 'QUALIFY'
  static BRIEF = 'BRIEF'
  static CAPABILITY = 'CAPABILITY'
  static PARTNER = 'PARTNER'
  static PRODUCT = 'PRODUCT'
  static WHY_US = 'WHY_US'
  static COPY_RIGHT = 'COPY_RIGHT'
}
