<template>
  <div class="footer-container">
    <img class="image" :src="backgroundImage" alt="">
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data () {
      return {
        backgroundImage: require('../../public/static/parner.jpg')
      }
    }
  }
</script>

<style scoped>
  .footer-container {
    margin-top: 100px;
    /*border: 1px solid red;*/
  }

  .image {
    width: 100%;
    vertical-align: top;
    border: inherit;
  }
</style>
