import ServiceImpl from '@/service/ServiceImpl'

class SliceService extends ServiceImpl {
  prefix = '/home/slice'

  constructor () {
    super()
    if (!SliceService.instance) {
      SliceService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return SliceService.instance
  }
}

class BriefService extends ServiceImpl {
  prefix = '/home/brief'

  constructor () {
    super()
    if (!BriefService.instance) {
      BriefService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return BriefService.instance
  }
}

class CapabilityService extends ServiceImpl {
  prefix = '/home/capability'

  constructor () {
    super()
    if (!CapabilityService.instance) {
      CapabilityService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return CapabilityService.instance
  }
}

class ProductService extends ServiceImpl {
  prefix = '/home/product'

  constructor () {
    super()
    if (!CapabilityService.instance) {
      CapabilityService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return CapabilityService.instance
  }
}

class WhyUsService extends ServiceImpl {
  prefix = '/home/why/us'

  constructor () {
    super()
    if (!WhyUsService.instance) {
      WhyUsService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return WhyUsService.instance
  }
}

class PartnerService extends ServiceImpl {
  prefix = '/home/partner'

  constructor () {
    super()
    if (!PartnerService.instance) {
      PartnerService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return PartnerService.instance
  }
}

class ContactService extends ServiceImpl {
  prefix = '/home/contact'

  constructor () {
    super()
    if (!ContactService.instance) {
      ContactService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return ContactService.instance
  }
}

class TitleService extends ServiceImpl {
  prefix = '/home/title'

  constructor () {
    super()
    if (!TitleService.instance) {
      TitleService.instance = this
    }
    this.urls = TitleService.adornUrls(this.prefix, this.resources)
    return TitleService.instance
  }
}

class CopyrightService extends ServiceImpl {
  prefix = '/home/copyright'

  constructor () {
    super()
    if (!CopyrightService.instance) {
      CopyrightService.instance = this
    }
    this.urls = CopyrightService.adornUrls(this.prefix, this.resources)
    return CopyrightService.instance
  }
}

export {
  SliceService,
  BriefService,
  CapabilityService,
  ProductService,
  WhyUsService,
  PartnerService,
  ContactService,
  TitleService,
  CopyrightService
}
