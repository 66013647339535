<template>
  <div class="product-list-container">
    <ul class="product-content">
      <li class="product-box"
          style="cursor: pointer;"
          v-for="product in (category||{}).items"
          @click="jumpToDetailPage(product)">
        <a :href="`/#/product?divisionId=${divisionId}&categoryId=${category.id}&productId=${product.id}`"
           @click.prevent>
          <product-cover :item="product"></product-cover>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import ProductCover from '@/components/ProductCover.vue'

export default {
  name: 'ProductListPanel',
  components: {ProductCover},
  props: {
    divisionId: {
      type: [String, Number],
      default: '-1'
    },
    category: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    // handler
    jumpToDetailPage (item) {
      this.$emit('enterDetail', item.id)
    }
  }
}
</script>

<style scoped>
.product-list-container {
  --product-box-height: 600;
  display: flex;
  justify-content: center;
  width: calc((var(--inner-width) - var(--aside-width) - 10) * 1px);

}

.product-content {
  width: 94%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  border: inherit;
}
</style>
