<template>
  <div class="category-container">
    <div class="title">{{ category.title }}</div>
    <div class="sub-title" v-for="sub in category.items"
         :key="sub.title"
         :class="{'is-activated':activatedSub === sub.id}"
         @click="changeActivatedSub(sub.id)">
      <a :href="`/#/product?divisionId=${category.id}&categoryId=${sub.id}`" @click.prevent>{{ sub.title }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Category',
  props: {
    category: {
      type: Object,
      default: () => ({})
    },
    activatedSub: {
      type: Number,
      default: -1
    }
  },
  methods: {
    changeActivatedSub (id) {
      this.$emit('activatedChange', this.category.id, id)
    }
  }
}
</script>

<style scoped>

.is-activated {
  background-color: darkgray !important;
  color: var(--main-theme-color);
}

.category-container {
  display: block;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: var(--main-theme-color);
}

.sub-title {

  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  background-color: lightgray;
  cursor: pointer;
}

.sub-title::selection {
  color: var(--main-theme-color);;
  background-color: transparent;
}

.sub-title:hover {
  color: var(--main-theme-color);
}
</style>
