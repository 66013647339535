import LanguageEnum from '@/enumeration/LanguageEnum'

export default class LanguageUtils {
  static LANG_KEY = 'lang'

  static justifyLanguage () {
    const language = window.localStorage.getItem(this.LANG_KEY)

    switch (language) {
      case LanguageEnum.CHINESE:
        return LanguageEnum.CHINESE
      case LanguageEnum.ENGLISH:
        return LanguageEnum.ENGLISH
      default:
        return LanguageEnum.CHINESE
    }
  }

  static setLanguage (lang) {
    window.localStorage.setItem(this.LANG_KEY, lang)
    window.location.reload()
  }
}
