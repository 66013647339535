<template>
  <div class="contact-container">
    <div class="contact-inner-box position-relative position-horizon-center">
      <span style="text-indent: 0;">{{description.field1 || ''}}</span>
      <span style="text-indent: 0;">{{description.field2 || ''}}</span>
      <div class="content-box">
        <div class="company-info-box">
          <div class="image-box">
            <el-carousel height="412px">
              <el-carousel-item
                v-for="image in images"
                :key="image.id"
              >
                <img class="image" :src="image.href || ''" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="info-box">
            <span style="text-indent: 0;">{{companyInfo.field1 || ''}}</span>
            <span style="text-indent: 0;">{{companyInfo.field2 || ''}}</span>
            <span style="text-indent: 0;">{{companyInfo.field3 || ''}}</span>
            <span style="text-indent: 0;">{{companyInfo.field4 || ''}}</span>
          </div>
        </div>
        <div class="contact-box">
          <el-form ref="form" :model="dataForm" label-width="80px">
            <el-form-item :label="notificationByLanguage('name')">
              <el-input v-model="dataForm.name"></el-input>
            </el-form-item>
            <el-form-item :label="notificationByLanguage('tel')">
              <el-input v-model="dataForm.tel"></el-input>
            </el-form-item>
            <el-form-item :label="notificationByLanguage('email')">
              <el-input v-model="dataForm.email"></el-input>
            </el-form-item>
            <el-form-item :label="notificationByLanguage('message')">
              <el-input
                type="textarea"
                v-model="dataForm.message"
                resize="140"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button round @click="onSubmit">{{notificationByLanguage('button')}}</el-button>
              <!--              <el-button>取消</el-button>-->
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Footer from '../../components/Footer'
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {InfoService, SliceService, SloganService} from '@/service/CompanyService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import LanguageEnum from "../../enumeration/LanguageEnum";

  const notifications = {
      name: {
        [LanguageEnum.CHINESE]:'姓名',
        [LanguageEnum.ENGLISH]:'name'
      },
      tel:{
        [LanguageEnum.CHINESE]:'电话',
        [LanguageEnum.ENGLISH]:'tel'
      },
      email:{
        [LanguageEnum.CHINESE]:'邮件',
        [LanguageEnum.ENGLISH]:'email'
      },
      message:{
        [LanguageEnum.CHINESE]:'留言',
        [LanguageEnum.ENGLISH]:'message'
      },
      button:{
        [LanguageEnum.CHINESE]:'提交',
        [LanguageEnum.ENGLISH]:'submit'
      }
  }
  const sloganService = new SloganService()
  const sliceService = new SliceService()
  const infoService = new InfoService()
  export default {
    name: 'Contact',
    components: {
      Footer
    },
    data () {
      return {
        sloganService,
        sliceService,
        infoService,
        description: {},
        images: [],
        companyInfo: {},
        dataForm: {}
      }
    },
    methods: {
      notificationByLanguage(key){
       return notifications[key][LanguageUtils.justifyLanguage()]
      },
      onSubmit () {
      },
      getSlogan () {
        const options = new Options()
        options.params = new DefaultParams(
          LanguageUtils.justifyLanguage(),
          SectionEnum.COMPANY,
          SpeciesEnum.OEM,
          LayoutEnum.SLOGAN
        )
        options.success = ({item}) => {
          this.description = item
        }
        this.sloganService.first(options)
      },
      getSlice () {
        const options = new Options()
        options.params = new DefaultParams(
          LanguageUtils.justifyLanguage(),
          SectionEnum.COMPANY,
          SpeciesEnum.OEM,
          LayoutEnum.LAYER
        )
        options.success = ({items}) => {
          this.images = items
        }
        this.sliceService.list(options)
      },
      getInfo () {
        const options = new Options()
        options.params = new DefaultParams(
          LanguageUtils.justifyLanguage(),
          SectionEnum.COMPANY,
          SpeciesEnum.OEM,
          LayoutEnum.ADDRESS
        )
        options.success = ({item}) => {
          this.companyInfo = item
        }
        this.infoService.first(options)
      }
    },
    created () {
      this.getSlogan()
      this.getSlice()
      this.getInfo()
    }
  }
</script>

<style scoped>

  .contact-box /deep/ .el-form-item__label {
    font-weight: bold;
  }

  .contact-container {
    --height: 760;

    height: calc(var(--height) * 1px);
    /*border: 1px solid red;*/
  }

  .contact-inner-box {
    width: calc(var(--inner-width) * 1px);
    height: 100%;
    padding-top: 40px;

    border: inherit;
  }

  .contact-inner-box > span {
    height: 60px;
    line-height: 60px;
    display: block;
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    text-indent: 4em;
    color: var(--main-theme-color);
  }

  .content-box {
    display: flex;
  }

  .company-info-box {
    width: 60%;
  }

  .image {
    width: 100%;
  }

  .info-box > span {
    width: 100%;
    display: block;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    text-indent: 4em;
  }

  .contact-box {
    width: 40%;
    padding: 0 10px;
  }


  ::v-deep .el-button {
    width: 140px;
    color: var(--white);
    background-color: var(--main-theme-color);
  }


</style>
