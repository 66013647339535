import Vue from 'vue'
import VueRouter from 'vue-router'
import MainFrame from '@/views/frame/MainFrame'
import Home from '@/views/home/Home'
import Brief from '@/views/brief/Brief'
import Capability from '@/views/capability/Capability'
import Product from '@/views/product/Product'
import Customize from '@/views/customize/Customize'
import Contact from '@/views/contact/Contact'
import Test from '@/views/Test'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// TODO: 启用懒加载
// const routes = [
//   {
//     path: '/', component: MainFrame, redirect: {name: 'Home'},
//     children: [
//       {path: '/main', component: Home, name: 'Home', meta: {title: '首页'}},
//       {path: '/brief', component: Brief, name: 'Brief', meta: {title: '关于我们'}},
//       {path: '/capability', component: Capability, name: 'Capability', meta: {title: '制造能力'}},
//       {
//         path: '/product',
//         component: Product,
//         name: 'Product',
//         meta: {title: '焊接产品'},
//         children: [
//           {path: '/product/detail/:id', component: ProductDetail, name: 'ProductDetail'},
//           {path: '/product/list/:id', component: ProductList, name: 'ProductList'},
//           {path: '/product/search', component: ProductSearch, name: 'ProductSearch', meta: {title: '产品搜索'}}
//         ]
//       },
//       {path: '/customize', component: Customize, name: 'Customize', meta: {title: '定制服务'}},
//       {path: '/company', component: Contact, name: 'Contact', meta: {title: '联系我们'}},
//       {path: '/test', component: Test, name: 'Test', meta: {title: '测试页'}}
//     ]
//   }
// ]

const routes = [
  {
    path: '/',
    component: MainFrame,
    redirect: {name: 'Home'},
    children: [
      {path: '/main', component: Home, name: 'Home', meta: {title: '首页'}},
      {path: '/brief', component: Brief, name: 'Brief', meta: {title: '关于我们'}},
      {path: '/capability', component: Capability, name: 'Capability', meta: {title: '制造能力'}},
      {path: '/product', component: Product, name: 'Product', meta: {title: '焊接产品'}},
      {path: '/customize', component: Customize, name: 'Customize', meta: {title: '定制服务'}},
      {path: '/company', component: Contact, name: 'Contact', meta: {title: '联系我们'}},
      {path: '/test', component: Test, name: 'Test', meta: {title: '测试页'}}
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  next()
})

export default router
