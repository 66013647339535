import ServiceImpl from '@/service/ServiceImpl'
import {BaseService, Options} from '@/service/BaseService'
import {cloneDeep, merge} from 'lodash'

class DivisionService extends ServiceImpl {
  prefix = '/division'

  constructor () {
    super()
    if (!DivisionService.instance) {
      DivisionService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return DivisionService.instance
  }
}

class CategoryService extends ServiceImpl {
  prefix = '/category'

  constructor () {
    super()
    if (!CategoryService.instance) {
      CategoryService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return CategoryService.instance
  }
}

class ProductService extends ServiceImpl {
  prefix = '/product'

  constructor () {
    super()
    if (!ProductService.instance) {
      ProductService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return ProductService.instance
  }

  search (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.prefix + '/search')
    super.inquire(options)
  }
}

export {
  DivisionService,
  CategoryService,
  ProductService
}
