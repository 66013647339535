<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
  import MainFrame from './views/frame/MainFrame'

  export default {
    name: 'App',
    components: {
      MainFrame
    }
  }
</script>
<style>
  .el-carousel__button {
    border: 4px solid var(--main-theme-color) !important;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }


  .viewer-toolbar > ul > .viewer-large {
    height: 40px !important;
    width: 40px !important;
  }

  .viewer-toolbar > ul > .viewer-large::before {
    margin: 10px !important;
  }

  .viewer-title + .viewer-title {
    font-size: 24px !important;
  }

</style>
