<template>
  <div class="footer-container">
    <div class="footer-inner-box position-absolute position-horizon-center">
      <span class="copy-right">{{copyright.field1}}</span>
      <span class="copy-right-phase">{{copyright.field2}}</span>
      <span><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备09070183号-1</a></span>
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import {CopyrightService} from '@/service/HomeService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'

  const service = new CopyrightService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.FOOTER,
    SpeciesEnum.OEM,
    LayoutEnum.INDEX
  )
  export default {
    name: 'CopyRight',
    data () {
      return {
        service,
        copyright: {}
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.copyright = item || {}
        }
        this.service.first(options)
      }
    },
    created () {
      this.getData()
      console.log(this.copyright)
    }
  }
</script>

<style scoped>

  .footer-container {
    --height: 100;
    height: calc(var(--height) * 1px);
    font-size: 16px;
    font-weight: bold;
    color: var(--main-theme-color);
    /*border: 1px solid red;*/
  }

  .footer-inner-box {
    width: calc(var(--inner-width) * 1px);
    height: calc(var(--height) * 1px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-top: 2px solid var(--main-theme-color);
    /*border: inherit;*/
  }

</style>
