<template>
  <div class="slice-container">

    <el-carousel height="700px" >
      <el-carousel-item style="z-index: -99" v-for="(image, index) in slices" :key="index">
        <img class="slice-images position-relative position-horizon-center"
             :src="image.href">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {SliceService} from '@/service/HomeService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new SliceService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.OEM,
    LayoutEnum.INDEX
  )
  export default {
    name: 'Slice',
    data () {
      return {
        service,
        slices: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.slices = items
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .slice-container {
    width: 100%;
  }

  .slice-container img {
    width: 100%;
    vertical-align: top;
  }
</style>
