<template>
  <div class="capability-container">
    <div class="capability-inner-box position-absolute position-horizon-center">
      <span class="capability-title">{{title.field1}}</span>
      <div class="capability-images position-horizon-center">
        <ul class="capability-images-container">
          <li class="capability-item"
              v-for="capability in capabilities">
            <router-link :to="{name:'Capability', params:{id:capability.id}}" class="capability-item">
              <img
                class="capability-image"
                :src="capability.thumb"
                :alt="capability.title"
              >
              <span class="capability-description">{{capability.title}}</span>
            </router-link>
          </li>
        </ul>
      </div>
      <router-link
        class="capability-more"
        :to="{name:'Capability'}">
        {{title.field2}}
      </router-link>
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {CapabilityService, TitleService} from '@/service/HomeService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new CapabilityService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.OEM,
    LayoutEnum.CAPABILITY
  )
  export default {
    name: 'Capability',
    data () {
      return {
        service,
        titleService,
        title: {},
        capabilities: []
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.capabilities = items
        }
        this.service.list(options)
      }
    },
    created () {
      this.getTitle()
      this.getData()
    }
  }
</script>

<style scoped>
  .capability-container {
    --height: 560;
    --title: 140;
    --more: 36;

    margin-bottom: 60px;
    height: calc(var(--height) * 1px);
    /*border: 1px solid red;*/
  }

  .capability-inner-box {
    width: calc(var(--inner-width) * 1px);
    border: inherit;
  }

  .capability-title {
    height: calc(var(--title) * 1px);
    line-height: calc(var(--title) * 1px);
    display: block;
    letter-spacing: .5em;
    font-size: 30px;
    font-weight: bold;
    border: inherit;
  }

  .capability-images {
    width: calc(var(--inner-width) * 1px);
    height: calc((var(--height) - var(--title) - var(--more)) * 1px);
    position: relative;
    border: inherit;
  }

  .capability-images-container {
    display: flex;
    justify-content: space-between;
  }

  .capability-item {
    flex-basis: calc(25% - 20px);
    width: 100%;
    height: calc((var(--height) - var(--title) - var(--more)) * 1px);
    border: 1px solid var(--main-theme-color);
    border-radius: 10px;
    cursor: pointer;
  }

  .capability-image {
    width: 100%;
    height: 80%;
    object-fit: cover;
    vertical-align: top;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .capability-description {
    height: 60px;
    line-height: 60px;
    margin: 0 auto;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
  }

  .capability-more {
    margin: 30px auto;
    padding: 0 10px;
    height: calc(var(--more) * 1px);
    line-height: calc(var(--more) * 1px);
    display: inline-block;
    font-size: 16px;
    color: gray;
    border: 1px solid var(--main-theme-color);
    border-radius: 10px;
  }

</style>
