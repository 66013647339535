<template>
  <div class="language-container">
    <div class="icon"
         v-for="(icon, key, index) in icons"
         :key="index"
         @click="changeLanguage(key)">
      <img :src="icon" :alt="key">
    </div>
  </div>
</template>

<script>
  import LanguageUtils from '@/utils/LanguageUtils'
  import LanguageEnum from '@/enumeration/LanguageEnum'

  export default {
    name: 'Language',
    data () {
      return {
        icons: {
          [LanguageEnum.CHINESE]: require('@public/static/language-cn.jpg'),
          [LanguageEnum.ENGLISH]: require('@public/static/language-en.jpg')
        }
      }
    },
    methods: {
      changeLanguage (code) {
        LanguageUtils.setLanguage(code)
      }
    }
  }
</script>

<style scoped>
  .language-container {
    display: flex;
    position: absolute;
    top: 0;
    left: calc(var(--inner-width) * 1px);
    z-index: 99;
  }

  .icon {
    width: 48px;
    margin: 2px;
    cursor: pointer;
  }

  .icon img {
    width: 100%;
  }
</style>
