<template>
  <div class="home-container">
    <Slice></Slice>
    <Brief></Brief>
    <Capability></Capability>
    <Product></Product>
    <WhyUs></WhyUs>
    <Partner></Partner>
    <Contact></Contact>
    <CopyRight></CopyRight>
  </div>
</template>

<script>
  import Slice from '@/views/home/components/Slice'
  import Brief from '@/views/home/components/Brief'
  import Capability from '@/views/home/components/Capability'
  import Product from '@/views/home/components/Product'
  import WhyUs from '@/views/home/components/WhyUs'
  import Partner from '@/components/Partner'
  import Contact from '@/views/home/components/Contact'
  import CopyRight from '@/views/home/components/CopyRight'

  export default {
    name: 'Home',
    components: {
      Slice,
      Brief,
      Capability,
      Product,
      WhyUs,
      Partner,
      Contact,
      CopyRight
    }
  }
</script>

<style scoped>

</style>
