<template>
  <div class="capability-container">
    <div class="capability-inner-box position-relative position-horizon-center">
      <div class="capability-navigate">
        <ul style="background-color: rgb(238, 241, 246)">
          <li
            class="capability-navigate-item"
            v-for="navigate in navigates"
            :key="navigate.id"
            :class="activatedNavigate.id === navigate.id?'is-activated':''"
            @click="activeNavigateItem(navigate)"
          >
            {{navigate.title}}
          </li>
        </ul>
      </div>
      <div class="capability-main">
        <el-carousel height="576px">
          <el-carousel-item v-for="(image, index) in activatedNavigate.images" :key="index">
            <img class="capability-images" :src="image.href" alt="">
          </el-carousel-item>
        </el-carousel>
        <span class="navigate-description">{{activatedNavigate.description}}</span>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Footer from '../../components/Footer'
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {CapabilityService} from '@/service/CapabilityService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new CapabilityService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.CAPABILITY,
    SpeciesEnum.OEM,
    LayoutEnum.LAYER
  )
  export default {
    name: 'Capability',
    components: {
      Footer
    },
    data () {
      return {
        service,
        activatedNavigate: {},
        navigates: []
      }
    },
    methods: {
      activeNavigateItem (navigate) {
        this.activatedNavigate = navigate
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.navigates = items || []
          if (this.navigates.length > 0) {
            this.activatedNavigate = this.navigates[0]
          }
        }
        this.service.list(options)
      }
    },
    mounted () {
      this.getData()
      const navigate = this.navigates.find(el => el.id === this.$route.params.id)
      this.activatedNavigate = navigate || this.navigates[0] || {}
    }
  }
</script>

<style scoped>
  .capability-container {
    --height: 700;
    --navigate: 200;
    --image-height: 576;

    height: calc(var(--height) * 1px);
    /*border: 1px solid red;*/
  }

  .capability-inner-box {
    margin-bottom: 100px;
    width: calc(var(--inner-width) * 1px);
    display: flex;
    border: inherit;
  }


  .capability-navigate {
    width: calc(var(--navigate) * 1px);
    border: inherit;
  }

  .capability-navigate-item {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }

  .capability-navigate-item:hover {
    color: var(--main-theme-color);
  }

  .capability-main {
    width: calc(100% - calc(var(--navigate) * 1px));
    height: calc(var(--height) * 1px);
  }

  .capability-images {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .navigate-description {
    width: 100%;
    height: calc((var(--height) - var(--image-height)) * 1px);
    display: inline-block;
    font-size: 16px;
    text-align: left;
    text-indent: 2em;
    word-wrap: break-word;
    border: inherit;
  }

  .is-activated {
    background-color: darkgray;
    color: var(--main-theme-color);
  }

</style>
