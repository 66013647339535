<template>
  <div class="brief-content-container">
    <div class="brief-inner-box position-relative position-horizon-center">
      <span class="title">{{title}}</span>
      <ul class="content-box">
        <li
          class="content-item"
          v-for="(item, index) in contentItems"
          :key="index">
          <span class="content-title">{{item.number}}+</span>
          <span class="content-description">{{item.title}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {BriefService} from '@/service/BriefService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import LanguageEnum from '@/enumeration/LanguageEnum'

  const service = new BriefService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.BRIEF,
    SpeciesEnum.OEM,
    LayoutEnum.LAYER
  )
  export default {
    name: 'BriefContent',
    computed: {
      title () {
        const titles = {
          [LanguageEnum.CHINESE]: '您身边的焊枪定制专家',
          [LanguageEnum.ENGLISH]: 'The Welding Torch Customizing Specialist Beside Of You'
        }
        return titles[LanguageUtils.justifyLanguage()]
      }
    },
    data () {
      return {
        service,
        contentItems: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.contentItems = items
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>

  .brief-content-container {
    --height: 350;
    --title: 100;

    margin-bottom: 60px;
    height: calc(var(--height) * 1px);
    /*border: 1px solid red;*/
  }


  .brief-inner-box {
    width: calc(var(--inner-width) * 1px);
    border: inherit;
  }

  .title {
    width: 100%;
    height: calc(var(--title) * 1px);
    display: inline-block;
    line-height: calc(var(--title) * 1px);
    font-size: 30px;
    font-weight: bold;
    border: inherit;
  }

  .content-box {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2, calc((var(--height) - var(--title)) * .5 * 1px));
    grid-template-columns: repeat(4, 1fr);
    border: inherit;
  }

  .content-item {
    display: flex;
    flex-direction: column;
    justify-content: center;

    border: inherit;
  }

  .content-title {
    color: var(--main-theme-color);
    font-weight: bold;
    font-size: 50px;
  }

  .content-description {
    font-size: 20px;
    color: gray;
    font-weight: lighter;
  }


</style>
