export default class SectionEnum {
  static INDEX = 'INDEX'
  static BRIEF = 'BRIEF'
  static CAPABILITY = 'CAPABILITY'
  static PRODUCT = 'PRODUCT'
  static CUSTOMIZE = 'CUSTOMIZE'
  static COMPANY = 'COMPANY'
  static CONTACT = 'CONTACT'
  static BANNER = 'BANNER'
  static FOOTER = 'FOOTER'
  static NAVIGATE = 'NAVIGATE'
}
