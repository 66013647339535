<template>
  <div class="brief-container">
    <BriefDescription></BriefDescription>
    <BriefContent></BriefContent>
    <Qualify></Qualify>
    <Footer></Footer>
  </div>
</template>

<script>
  import BriefDescription from '@/views/brief/components/BriefDescription'
  import BriefContent from '@/views/brief/components/BriefContent'
  import Qualify from '@/views/brief/components/Qualify'
  import Footer from '@/components/Footer'

  export default {
    name: 'Brief',
    components: {
      BriefDescription,
      BriefContent,
      Qualify,
      Footer
    },
    data () {
      return {}
    }
  }
</script>

<style scoped>
</style>
