<template>
  <div class="product-container position-relative position-horizon-center">
    <div class="aside">
      <category
        v-for="c in categories"
        :category="c"
        :key="c.title"
        :activated-sub="categoryId"
        @activatedChange="onActivatedChange"/>
      <div class="search-box">
        <div class="info-box" @click="onEnterSearch">
          <img class="search-icon" :src="searchIcon">
          <span>{{ notificationByLanguage('search') }}</span>
        </div>
        <input type="text" v-model="searchKeyword" @keyup.enter.stop.self="onEnterSearch">
      </div>
    </div>
    <product-list-panel
      v-if="activatedPanel===PanelEnum.List"
      :division-id="divisionId"
      :category="category"
      @enterDetail="onEnterDetail"/>
    <product-detail-panel
      v-if="activatedPanel===PanelEnum.Detail"
      :product="product"
      @returnToList="onReturnToList"/>
    <product-search-panel
      v-if="activatedPanel===PanelEnum.Search"
      :products="matchedProducts"
      :keyword="searchKeyword"
      @enterDetail="onEnterDetail"
    />
  </div>
</template>

<script>
import {cloneDeep, merge} from 'lodash'
import {DefaultParams, Options} from '@/service/BaseService'
import LanguageUtils from '@/utils/LanguageUtils'
import SectionEnum from '@/enumeration/SectionEnum'
import SpeciesEnum from '@/enumeration/SpeciesEnum'
import LayoutEnum from '@/enumeration/layoutEnum'
import {CategoryService, DivisionService, ProductService} from '@/service/ProductService'
import LanguageEnum from '@/enumeration/LanguageEnum'
import Category from '@/views/product/components/Category.vue'
import ProductListPanel from '@/views/product/components/ProductListPanel.vue'
import ProductDetailPanel from '@/views/product/components/ProductDetailPanel.vue'
import ProductSearchPanel from '@/views/product/components/ProductSearchPanel.vue'

const divisionService = new DivisionService()
const categoryService = new CategoryService()
const productService = new ProductService()

class PanelEnum {
  static List = 'list'
  static Detail = 'detail'
  static Search = 'search'
}

const divisionParams = new DefaultParams(
  LanguageUtils.justifyLanguage(),
  SectionEnum.PRODUCT,
  SpeciesEnum.OEM,
  LayoutEnum.DIVISION
)
const categoryParams = new DefaultParams(
  LanguageUtils.justifyLanguage(),
  SectionEnum.PRODUCT,
  SpeciesEnum.OEM,
  LayoutEnum.CATEGORY
)
const productParams = new DefaultParams(
  LanguageUtils.justifyLanguage(),
  SectionEnum.PRODUCT,
  SpeciesEnum.OEM,
  LayoutEnum.PRODUCT
)
const searchParams = new DefaultParams(
  LanguageUtils.justifyLanguage(),
  SectionEnum.PRODUCT,
  SpeciesEnum.OEM,
  LayoutEnum.COVER
)
export default {
  name: 'Product',
  components: {
    Category, ProductListPanel, ProductDetailPanel, ProductSearchPanel
  },
  watch: {
    $route: {
      handler () {
        this.judge()
      },
      immediate: true
    }
  },
  computed: {
    divisionId () {
      const {divisionId} = this.$route.query
      return parseInt(divisionId || null)
    },
    categoryId () {
      const {categoryId} = this.$route.query
      return parseInt(categoryId || null)
    },
    productId () {
      const {productId} = this.$route.query
      return parseInt(productId || null)
    }
  },
  methods: {
    getCategories () {
      const options = new Options()
      options.params = divisionParams
      options.success = ({items}) => {
        this.categories = items || []
        this.judge()
      }
      this.divisionService.list(options)
    },
    getCategory () {
      const options = new Options()
      options.params = merge(categoryParams, {id: this.categoryId})
      options.success = ({item}) => {
        this.category = item
        this.category.items.sort((a, b) => {
          return a.sequence - b.sequence
        })
      }
      this.categoryService.first(options)
    },
    getProduct () {
      const options = new Options()
      options.params = merge(productParams, {id: this.productId})
      options.success = ({item}) => {
        this.product = item
        this.product.components.sort((a, b) => {
          if (a.sequence !== b.sequence) {
            return a.sequence - b.sequence
          }
          return a.number - b.number
        })
        this.product.species.sort((a, b) => a.number - b.number)
      }
      this.productService.first(options)
    },

    // data process
    async judge () {
      if (this.productId) {
        this.activatedPanel = PanelEnum.Detail
        this.getProduct()
        return
      }
      this.activatedPanel = PanelEnum.List
      if (!(this.categories.length > 0)) return
      if (this.divisionId && this.categoryId) {
        this.getCategory()
        return
      }
      let divisionId = this.divisionId
      let categoryId = this.categoryId
      if (!divisionId) {
        divisionId = this.categories[0].id
      }
      if (!categoryId) {
        const matched = this.categories.find(c => c.id === divisionId)
        if (matched && matched.items.length > 0) {
          categoryId = matched.items[0].id
        }
      }
      await this.$router.push({query: {divisionId, categoryId}})
    },
    notificationByLanguage (key) {
      const notifies = {
        search: {
          [LanguageEnum.CHINESE]: '请输入编号',
          [LanguageEnum.ENGLISH]: 'Enter Code'
        }
      }
      return notifies[key][LanguageUtils.justifyLanguage()]
    },
    // event handler
    onActivatedChange (divisionId = -1, categoryId = -1) {
      this.activatedPanel = this.PanelEnum.List
      this.$router.push({query: {divisionId, categoryId}})
    },
    onEnterDetail (id) {
      const query = {...this.$route.query, productId: id}
      this.$router.push({query})
    },
    onEnterSearch () {
      this.activatedPanel = this.PanelEnum.Search
      const options = new Options()
      options.params = merge(cloneDeep(searchParams), {keyword: this.searchKeyword})
      options.success = ({items}) => {
        this.matchedProducts = items || []
        this.matchedProducts.forEach(e => {
          e.description = e.description.split('\n')
        })
      }
      this.productService.search(options)
    },
    onReturnToList () {
      const query = {...this.$route.query}
      delete query['productId']
      this.$router.push({query})
    }
  },
  data () {
    // 使用合并相同的对象，避免重复创建对象
    const categories = []
    const category = {}
    const product = {}
    const activatedPanel = PanelEnum.List
    const searchKeyword = ''
    const searchIcon = require('@public/static/search_icon.png')
    const matchedProducts = []
    return {
      divisionService,
      categoryService,
      productService,
      categories,
      category,
      product,
      activatedPanel,
      PanelEnum,
      searchKeyword,
      searchIcon,
      matchedProducts
    }
  },
  created () {
    this.getCategories()
  }
}
</script>

<style scoped>
.product-container {
  --aside-width: 200;

  width: calc(var(--inner-width) * 1px);
  display: flex;
}

.aside {
  width: calc(var(--aside-width) * 1px);
  height: 100%;

  border: inherit;
}


.search-box {
  margin-top: 60px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
}

.info-box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.info-box span {
  font-style: italic;
}

.info-box .search-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.search-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.search-box input {
  margin: 10px;
  padding: 0 10px;
  height: 40px;
  border: 1px solid var(--main-theme-color);
  border-radius: 10px;
  outline: var(--main-theme-color) none;
}

.search-box input::selection {
  background-color: lightgray;
}
</style>
