import ServiceImpl from '@/service/ServiceImpl'

class SloganService extends ServiceImpl {
  prefix = '/company/slogan'

  constructor () {
    super()
    if (!SloganService.instance) {
      SloganService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return SloganService.instance
  }
}

class SliceService extends ServiceImpl {
  prefix = '/company/slice'

  constructor () {
    super()
    if (!SliceService.instance) {
      SliceService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return SliceService.instance
  }
}

class InfoService extends ServiceImpl {
  prefix = '/company/info'

  constructor () {
    super()
    if (!InfoService.instance) {
      InfoService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return InfoService.instance
  }
}

export {
  SloganService,
  SliceService,
  InfoService
}
