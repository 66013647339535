<template>
  <div class="banner-container">
    <div class="banner-inner-box position-relative position-horizon-center">
      <img class="banner-image" :src="banner.href || ''" alt="">
      <language></language>
    </div>
  </div>
</template>


<script>
  import Language from '@/views/frame/components/language'
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {BannerService} from '@/service/FrameService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new BannerService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.BANNER,
    SpeciesEnum.OEM,
    LayoutEnum.INDEX
  )
  export default {
    name: 'Banner',
    components: {Language},
    data () {
      return {
        service,
        banner: {}
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.banner = item || {}
        }
        this.service.first(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .banner-container {
    /*border: 1px solid red;*/
  }

  .banner-inner-box {
    width: calc(var(--inner-width) * 1px);
    border: inherit;
  }

  .banner-image {
    width: calc(var(--inner-width) * 1px);
    height: 100%;
    vertical-align: top;
    object-fit: cover;
  }
</style>
