<template>
  <div class="navigate-container">
    <ul class="navigate-item-container position-relative position-horizon-center">
      <li class="navigate-item" v-for="navigate in navigates">
        <router-link :to="navigate.thumb" active-class="is-active">{{navigate.title}}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {NavigateService} from '@/service/FrameService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new NavigateService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.NAVIGATE,
    SpeciesEnum.OEM,
    LayoutEnum.INDEX
  )
  export default {
    name: 'Navigation',
    data () {
      return {
        service,
        navigates: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.navigates = items
        }
        this.service.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .navigate-container {
    margin-bottom: 4px;
    height: 40px;
    /*border: 1px solid red;*/
  }

  .navigate-item-container {
    width: calc(var(--inner-width) * 1px);
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: inherit;
  }

  .navigate-item {
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    border: inherit;
  }

  .is-active,
  .navigate-item:hover {
    color: var(--main-theme-color);
  }

</style>
