<template>
  <div class="qualify-container">
    <div class="qualify-inner-box position-relative position-horizon-center">
      <span class="title">{{title}}</span>
      <div class="gallery">
        <ul class="photo-wall">
          <li class="photo-item"
              v-for="item in photos"
              @click="preview(item.href)"
          >
            <img class="photo" :src="item.href">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {QualifyService} from '@/service/BriefService'
  import LanguageUtils from '@/utils/LanguageUtils'
  import LanguageEnum from '@/enumeration/LanguageEnum'

  const service = new QualifyService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.BRIEF,
    SpeciesEnum.OEM,
    LayoutEnum.QUALIFY
  )
  export default {
    name: 'Qualify',
    computed: {
      title () {
        const titles = {
          [LanguageEnum.CHINESE]: '企业资质与专利',
          [LanguageEnum.ENGLISH]: 'Qualify And Patent'
        }
        return titles[LanguageUtils.justifyLanguage()]
      }
    },
    data () {
      return {
        service,
        photos: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.photos = items || []
        }
        this.service.list(options)
      },
      preview (href) {
        this.$viewerApi({
          images: [href],
          options: {rotatable: false, scalable: false}
        })
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>

  .qualify-container {
    /*border: 1px solid red;*/
  }

  .qualify-inner-box {
    width: calc(var(--inner-width) * 1px);
    border: inherit;
  }

  .title {
    margin-bottom: 60px;
    height: 200px;
    line-height: 200px;
    display: block;
    font-size: 30px;
    font-weight: bold;
    /*letter-spacing: .5em;*/
  }

  .gallery {
    margin-top: 60px;
    width: inherit;
    height: 180px;
    position: absolute;
    top: 60%;
    border: inherit;
  }

  .photo-wall {
    height: inherit;
    display: flex;
    justify-content: space-around;
  }

  .photo-item {
    width: 140px;
    display: inline-block;
    background-color: red;
    border: 1px solid var(--main-theme-color);
    cursor: pointer;
  }

  .photo {
    width: 100%;
  }


  .background-image {
    width: 100%;
  }

</style>
