<template>
  <div class="brief-description-container">
    <div class="brief-description-inner-box position-relative position-horizon-center">
      <div class="image">
        <el-carousel height="400px">
          <el-carousel-item v-for="(image, index) in images" :key="index">
            <img :src="image.href" alt="" style="width: 100%">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="description">
        <div class="description-inner-box">
          <p class="description-title">{{description.field1}}</p>
          <p class="description-content">{{description.field2}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {DescriptionService, SliceService} from '@/service/BriefService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const descriptionService = new DescriptionService()
  const sliceService = new SliceService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.BRIEF,
    SpeciesEnum.OEM,
    LayoutEnum.LAYER
  )
  export default {
    name: 'BriefDescription',
    data () {
      return {
        descriptionService,
        sliceService,
        images: [],
        description: {}
      }
    },
    methods: {
      getDescription () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.description = item || {}
        }
        this.descriptionService.first(options)
      },
      getSlice () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.images = items || []
        }
        this.sliceService.list(options)
      }
    },
    created () {
      this.getDescription()
      this.getSlice()
    }
  }
</script>

<style scoped>

  .brief-description-container {
    margin-bottom: 60px;
    height: 400px;
    display: flex;
    /*border: 1px solid red;*/
  }

  .brief-description-inner-box {
    width: calc(var(--inner-width) * 1px);
    border: inherit;
  }

  .image {
    width: 60%;
    height: 100%;
    float: left;
  }

  .description {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: right;
  }

  .description-inner-box {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    border: inherit;
  }

  .description-title {
    width: 100%;
    float: left;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    border: inherit;
  }

  .description-content {
    width: 100%;
    text-indent: 2em;
    float: left;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    word-wrap: break-word;
    border: inherit;
  }


</style>
