import ServiceImpl from '@/service/ServiceImpl'

class NavigateService extends ServiceImpl {
  prefix = '/frame/navigate'

  constructor () {
    super()
    if (!NavigateService.instance) {
      NavigateService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return NavigateService.instance
  }
}

class BannerService extends ServiceImpl {
  prefix = '/frame/banner'

  constructor () {
    super()
    if (!BannerService.instance) {
      BannerService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return BannerService.instance
  }
}

export {
  NavigateService,
  BannerService
}
