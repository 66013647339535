<template>
  <div class="customize-container">
    <div class="customize-inner-box position-relative position-horizon-center">
      <span class="title">{{title}}</span>
      <span class="greet">{{greet}}</span>
      <img
        class="flow-image"
        v-for="(image, index) in flowImage"
        :key="index"
        :src="image.href">
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Footer from '../../components/Footer'
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {SliceService, SloganService} from '@/service/SliceService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const sliceService = new SliceService()
  const sloganService = new SloganService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.CUSTOMIZE,
    SpeciesEnum.OEM,
    LayoutEnum.LAYER
  )
  export default {
    name: 'Customize',
    components: {
      Footer
    },
    data () {
      return {
        sliceService,
        sloganService,
        title: '',
        greet: '',
        flowImage: []
      }
    },
    methods: {
      getSlogan () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item.field1 || ''
          this.greet = item.field2 || ''
        }
        this.sloganService.first(options)
      },
      getSlice () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.flowImage = items
        }
        this.sliceService.list(options)
      }
    },
    created () {
      this.getSlogan()
      this.getSlice()
    }
  }
</script>

<style scoped>

  .customize-container {
    /*border: 1px solid red;*/
  }


  .customize-inner-box {
    padding-top: 40px;
    width: calc(var(--inner-width) * 1px);
    display: flex;
    flex-direction: column;
  }

  .contact-inner-box span {
    display: block;
  }

  .title {
    font-size: 40px;
    font-weight: bold;
    text-align: left;
    text-indent: 3em;
    color: var(--main-theme-color);
  }

  .greet {
    padding-top: 20px;
    font-size: 26px;
    text-align: left;
    text-indent: 3em;
    color: lightgray;
  }

  .flow-image {
    width: 100%;
  }
</style>
