<template>
  <div class="contact-container">
    <div class="contact-inner-box position-relative position-horizon-center">
      <div class="logo-area">
        <img :src="logo" alt="" class="contact-logo">
        <span class="logo-description">亿诺焊接</span>
      </div>
      <div class="contact-items">
        <div class="contact-item">
          <i class="iconfont icon-phone"></i>
          <span>{{contact.field1 || ''}}</span>
        </div>
        <div class="contact-item">
          <i class="iconfont icon-email"></i>
          <span>{{contact.field2 || ''}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import {ContactService} from '@/service/HomeService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new ContactService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.CONTACT,
    SpeciesEnum.OEM,
    LayoutEnum.INDEX
  )
  export default {
    name: 'Contact',
    data () {
      return {
        service,
        logo: require('../../../../public/static/logo.jpg'),
        contact: {}
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.contact = item || {}
        }
        this.service.first(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .contact-container {
    --height: 320;
    --logo: 100;

    height: calc(var(--height) * 1px);
    position: relative;
    /*border: 1px solid red;*/
  }

  .contact-inner-box {
    width: calc(var(--inner-width) * 1px);
    height: 100%;
    border: inherit;
  }

  .logo-area {
    padding-left: 100px;
    height: calc(var(--logo) * 1px);
    border: inherit;
  }

  .contact-logo {
    margin-top: calc(var(--logo) * .5 * 1px);
    height: calc(var(--logo) * .5 * 1px);
    float: left;
  }

  .logo-description {
    margin-top: calc(var(--logo) * .5 * 1px);
    margin-left: 10px;
    line-height: calc(var(--logo) * .5 * 1px);
    font-size: 30px;
    font-weight: bold;
    letter-spacing: .5em;
    float: left;
  }

  .contact-items {
    height: calc((var(--height) - var(--logo)) * 1px);
    margin: auto 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .contact-item {
    width: 400px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--main-theme-color);
    border: inherit;
  }

  .contact-item i {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    display: inline-block;
    line-height: 50px;
    font-size: 20px;
    font-weight: lighter;
    color: white;
    background-color: var(--main-theme-color);
    border-radius: 50%;
  }
</style>
