import Vue from 'vue'

import Vuex from 'vuex'
import {stageBarAbout, stageLogoAbout, stageScreenAbout} from './stage'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    stageBarAbout,
    stageLogoAbout,
    stageScreenAbout
  }
})
