<template>
  <div class="partner-container position-relative">
    <img class="partner-background" :src="partner.img" alt="">
    <div class="partner-content position-absolute position-horizon-center">
      <span class="partner-title">{{title.field1}}</span>
      <ul class="partner-logo-container">
        <li class="partner-logo"
            v-for="logo in partner.partnerLogos">
          <img :src="logo.href" alt="" class="partner-logo-image">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import {PartnerService, TitleService} from '@/service/HomeService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new PartnerService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.OEM,
    LayoutEnum.PARTNER
  )
  export default {
    name: 'Partner',
    data () {
      return {
        service,
        titleService,
        title: {},
        partner: {
          img: require('../../public/static/parner.jpg'),
          partnerLogos: []
        }
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.partner.partnerLogos = items
        }
        this.service.list(options)
      }
    },
    created () {
      this.getTitle()
      this.getData()
    }
  }
</script>

<style scoped>
  .partner-container {
    --height: 485;
    --title: 140;

    height: calc(var(--height) * 1px);
    /*border: 1px solid red;*/
  }


  .partner-background {
    opacity: .5;
    width: 100%;
  }

  .partner-content {
    width: calc(var(--inner-width) * 1px);
    height: 100%;
    top: 0;
    border: inherit;
  }

  .partner-title {
    display: inline-block;
    width: 100%;
    height: calc(var(--title) * 1px);
    line-height: calc(var(--title) * 1px);
    font-size: 30px;
    font-weight: bold;
    border: inherit;
  }

  .partner-logo-container {
    width: 100%;
    height: calc((var(--height) - var(--title)) * 1px);
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: calc((var(--height) - var(--title) * 2) * 1px);
    border: inherit;
  }

  .partner-logo {
    width: 240px;
    height: 160px;
    border: inherit;
  }

</style>
