const common = {}

const stageLogoAbout = {
  namespaced: true,
  state: {
    isVisible: false,
    href: '',
    arrow: '',
    menus: []
  },
  actions: {},
  mutations: {
    exhibit (state, value) {
      state.isVisible = true
    },
    hide (state, value) {
      state.isVisible = false
    },
    setLogoHref (state, value) {
      state.href = value
    },
    setArrow (state, value) {
      state.arrow = value
    },
    setMenus (state, value) {
      state.menus = value
    }
  }
}

const stageBarAbout = {
  namespaced: true,
  state: {
    isVisible: false,
    barMenus: []
  },
  actions: {},
  mutations: {
    exhibit (state, value) {
      state.isVisible = true

    },
    hide (state, value) {
      state.isVisible = false
    },
    setBarMenus (state, values) {
      state.barMenus = values
    }
  }
}

const stageScreenAbout = {
  namespaced: true,
  state: {
    navigateDescriptions: [],
    currentBarMenu: {},
    series: []
  },
  actions: {},
  mutations: {
    setNavigateDescriptions (state, values) {
      state.navigateDescriptions = values
    },
    setCurrentBarMenu (state, value) {
      state.currentBarMenu = value
    },
    setSeries (state, values) {
      state.series = values
    }
  },
  getters: {
    currentDescription (state) {
      if (!state.currentBarMenu) return {}
      const entity = state.navigateDescriptions.find(el => {
        return el.navigateItemId === state.currentBarMenu.navigateItemId
      })
      return entity || {}
    },
    currentSeries (state) {
      if (!state.currentBarMenu) return []
      const series = state.series.filter(el => {
        return el.navigateItemId === state.currentBarMenu.navigateItemId
      })
      return series || []
    }
  }
}

export {
  stageLogoAbout,
  stageBarAbout,
  stageScreenAbout
}
