import ServiceImpl from '@/service/ServiceImpl'

class CapabilityService extends ServiceImpl {
  prefix = '/capability'

  constructor () {
    super()
    if (!CapabilityService.instance) {
      CapabilityService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return CapabilityService.instance
  }
}

export {
  CapabilityService
}
