<template>
  <div class="product-container">
    <div class="product-inner-box position-relative position-horizon-center">
      <span class="product-title">{{ title.field1 }}</span>
      <div class="product-images">
        <ul class="product-images-container">
          <li class="product-item" v-for="(product, index) in products" :key="index">
            <router-link class="product-item" :to="{name:'Product', query:{divisionId: product.relatedId}}">
              <img class="product-image"
                   :src="product.thumb" alt="product.description">
              <span class="product-description">{{ product.title }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {DefaultParams, Options} from '@/service/BaseService'
import SectionEnum from '@/enumeration/SectionEnum'
import SpeciesEnum from '@/enumeration/SpeciesEnum'
import LayoutEnum from '@/enumeration/layoutEnum'
import {ProductService, TitleService} from '@/service/HomeService'
import LanguageUtils from '@/utils/LanguageUtils'

const service = new ProductService()
const titleService = new TitleService()
const params = new DefaultParams(
  LanguageUtils.justifyLanguage(),
  SectionEnum.INDEX,
  SpeciesEnum.OEM,
  LayoutEnum.PRODUCT
)
export default {
  name: 'Product',
  data () {
    return {
      service,
      titleService,
      title: {},
      products: []
    }
  },
  methods: {
    getTitle () {
      const options = new Options()
      options.params = params
      options.success = ({item}) => {
        this.title = item || {}
      }
      this.titleService.first(options)
    },
    getData () {
      const options = new Options()
      options.params = params
      options.success = ({items}) => {
        this.products = items
      }
      this.service.list(options)
    }
  },
  created () {
    this.getTitle()
    this.getData()
  }
}
</script>

<style scoped>
.product-container {
  --height: 550;
  --title: 140;
  --description: 60;

  margin-bottom: 60px;
  height: calc(var(--height) * 1px);
  /*border: 1px solid red;*/
}

.product-inner-box {
  width: calc(var(--inner-width) * 1px);
  height: 100%;
  border: inherit;
}

.product-title {
  height: calc(var(--title) * 1px);
  line-height: calc(var(--title) * 1px);
  display: block;
  letter-spacing: .5em;
  font-size: 30px;
  font-weight: bold;
  border: inherit;
}

.product-images {
  width: calc(var(--inner-width) * 1px);
  height: calc((var(--height) - var(--title) - var(--description)) * 1px);
  border: inherit;
}

.product-images-container {
  display: flex;
  justify-content: space-between;
}

.product-item {
  /*margin-right: 30px;*/
  width: 100%;
  height: calc((var(--height) - var(--title)) * 1px);
  flex-basis: calc(25% - 20px);
  border: 1px solid var(--main-theme-color);
  border-radius: 10px;
}


.product-image {
  width: 100%;
  height: calc((var(--height) - var(--title) - var(--description)) * 1px);
  object-fit: cover;
  vertical-align: top;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.product-description {
  display: block;
  height: calc(var(--description) * 1px);
  line-height: calc(var(--description) * 1px);
  font-size: 20px;
  font-weight: bold;
  border-top: inherit;
}
</style>
