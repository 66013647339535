<template>
  <el-card class="card" shadow="hover" :body-style="{padding: 0}">
    <img :src="getProperty(item, 'thumb')" class="image">
    <span class="title">{{ getProperty(item, 'title') }}</span>
    <el-divider class="divider-color divider-width"></el-divider>
    <ul class="content">
      <li class="description" v-for="des in getProperty(item, 'descriptions', [])" :key="des.id">
        <div class="content-wrap">
          <img :src="getProperty(des, 'thumb')" :style="{width: des.title + 'px', height: des.title + 'px'}">
          <div class="span">{{ getProperty(des, 'description') }}</div>
        </div>
      </li>
    </ul>
  </el-card>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ProductCover',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    getProperty (o, path, defaultValue = '') {
      return _.get(o, path, defaultValue)
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 20px;
  width: 240px;
  border-color: var(--main-theme-color);
  cursor: pointer;
}


.card:hover {

  border-color: transparent;
  box-shadow: 0 4px 16px 0 #EB6100 !important;
}

.divider-color {
  margin: 0 auto;
  background-color: var(--main-theme-color);
}

.divider-width {
  width: 80%;
  height: 2px;
  margin: 12px auto;
}

.title {
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
}

.image {
  width: 100%;
  /*height: calc(var(--product-box-height) * 1px * .6);*/
  object-fit: cover;
}

.description {
  /*padding-left: 10%;*/
  margin-top: 6px;
  width: 100%;
  display: flex;
  align-items: center;
}

.content {
  width: inherit;
  margin: 20px auto;

}

.description .content-wrap {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;

}

.description img {
  margin-right: 12px;
}

.description i {
  width: 20%;
  display: inline-block;
}

.description .span {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
</style>
