<template>
  <div class="brief-container">
    <router-link class="brief-inner-box position-absolute position-horizon-center"
                 :to="{name:'Brief'}">
      <div class="brief-title">
        <span>{{title.field1}}</span>
      </div>
      <div class="brief-content-container" ref="briefContent">
        <ul class="brief-item-container position-horizon-center">
          <li class="brief-item"
              v-for="(item, index) in items"
              :key="index">
            <countTo class="brief-content-major"
                     ref="countTo"
                     separator=""
                     suffix="+"
                     :startVal="0"
                     :endVal="item.number"
                     :duration="countToConfig.duration"
                     :autoplay="false"
            ></countTo>
            <span class="brief-content-description">{{item.title}}</span>
          </li>
        </ul>
      </div>
      <div class="brief-more">
        <span>{{title.field2}}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
  import countTo from 'vue-count-to'
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {BriefService, TitleService} from '@/service/HomeService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new BriefService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.OEM,
    LayoutEnum.BRIEF
  )
  export default {
    name: 'Brief',
    components: {
      countTo
    },
    data () {
      return {
        service,
        titleService,
        countToConfig: {
          duration: 2500,
          activated: false
        },
        title: {},
        items: []
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({items}) => {
          this.items = items || []
        }
        this.service.list(options)
      },
      onScroll (e) {
        let position = this.$refs.briefContent.getBoundingClientRect().top
        if (position < 700 && this.countToConfig.activated === false) {
          window.removeEventListener('scroll', this.onScroll)
          this.countToConfig.activated = true
          this.$refs.countTo.forEach(component => {
            component.reset()
            component.start()
          })
        }
      }
    },
    created () {
      this.getTitle()
      this.getData()
      window.addEventListener('scroll', this.onScroll)
    }
  }
</script>

<style scoped>

  .brief-container {
    --height: 446;
    --title: 140;
    --more: 36;
    height: calc(var(--height) * 1px);
    /*border: 1px solid red;*/
  }

  .brief-inner-box {
    width: calc(var(--inner-width) * 1px);
  }

  .brief-title span {
    height: calc(var(--title) * 1px);
    line-height: calc(var(--title) * 1px);
    display: block;
    letter-spacing: .2em;
    font-size: 30px;
    font-weight: bold;
    border: inherit;
  }

  .brief-item-container {
    width: calc(var(--inner-width) * 1px);
    height: calc((var(--height) - var(--title) - var(--more)) * 1px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .brief-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: inherit;
  }

  .brief-content-major {
    font-size: 80px;
    font-weight: bold;
    color: var(--main-theme-color);
  }

  .brief-content-description {
    font-size: 20px;
    font-weight: bold;
    color: gray;
  }

  .brief-more {
    height: calc(var(--more) * 1px);
    border: inherit;
  }

  .brief-more span {
    height: 36px;
    padding: 0 10px;
    line-height: 36px;
    display: inline-block;
    font-size: 16px;
    color: gray;
    border: 1px solid var(--main-theme-color);
    border-radius: 10px;
  }

</style>
