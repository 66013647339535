import ServiceImpl from '@/service/ServiceImpl'

class DescriptionService extends ServiceImpl {
  prefix = '/brief/description'

  constructor () {
    super()
    if (!DescriptionService.instance) {
      DescriptionService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return DescriptionService.instance
  }
}

class SliceService extends ServiceImpl {
  prefix = '/brief/slice'

  constructor () {
    super()
    if (!SliceService.instance) {
      SliceService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return SliceService.instance
  }
}

class BriefService extends ServiceImpl {
  prefix = '/brief/brief'

  constructor () {
    super()
    if (!BriefService.instance) {
      BriefService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return BriefService.instance
  }
}

class QualifyService extends ServiceImpl {
  prefix = '/brief/qualify'

  constructor () {
    super()
    if (!QualifyService.instance) {
      QualifyService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return QualifyService.instance
  }
}

export {
  DescriptionService,
  SliceService,
  BriefService,
  QualifyService
}
