<template>
  <div class="product-search-panel">
    <div class="wrap">
      <div class="empty" v-if="products.length===0">
        <span>[ {{ keyword }} ] {{ emptyString }}</span>
      </div>
      <div class="items" v-else>
        <div v-for="item in products" :key="item.id" @click="jumpToDetailPage(item)">
          <product-cover class="item" :item="item"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageEnum from '@/enumeration/LanguageEnum'
import LanguageUtils from '@/utils/LanguageUtils'
import ProductCover from '@/components/ProductCover.vue'

export default {
  name: 'ProductSearchPanel',
  components: {
    ProductCover
  },
  props: {
    keyword: {
      type: String, default: ''
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    emptyString () {
      const words = {
        [LanguageEnum.ENGLISH]: 'Sorry, There\'s Nothing',
        [LanguageEnum.CHINESE]: '没有搜索到您查询的产品。'
      }
      return words[LanguageUtils.justifyLanguage()]
    }
  },
  methods: {
    // handler
    jumpToDetailPage (item) {
      this.$emit('enterDetail', item.id)
    }
  }
}
</script>

<style scoped>

.product-search-panel {
  display: flex;
  justify-content: center;
  width: calc((var(--inner-width) - var(--aside-width) - 10) * 1px);
}

.wrap {
  width: 94%;
}

.empty {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 26px;
}

.items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr  );
  grid-gap: 20px;
  border: inherit;
}
</style>
