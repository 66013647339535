<template>
  <div class="test-container">
    <ul>
      <li class="list-item">
        <a href="">
          <span class="icon"></span>
          <span class="text"></span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'Test',
    data () {
      return {

      }
    }
  }
</script>

<style scoped>

</style>
