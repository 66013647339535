<template>
  <div class="why-us-container">
    <div class="why-us-inner-box position-absolute position-horizon-center">
      <span class="why-us-title">{{title.field1}}</span>
      <img class="why-us-image" :src="whyUs.thumb" alt="">
    </div>
  </div>
</template>

<script>
  import {DefaultParams, Options} from '@/service/BaseService'
  import SectionEnum from '@/enumeration/SectionEnum'
  import SpeciesEnum from '@/enumeration/SpeciesEnum'
  import LayoutEnum from '@/enumeration/layoutEnum'
  import {TitleService, WhyUsService} from '@/service/HomeService'
  import LanguageUtils from '@/utils/LanguageUtils'

  const service = new WhyUsService()
  const titleService = new TitleService()
  const params = new DefaultParams(
    LanguageUtils.justifyLanguage(),
    SectionEnum.INDEX,
    SpeciesEnum.OEM,
    LayoutEnum.WHY_US
  )
  export default {
    name: 'WhyUs',
    data () {
      return {
        service,
        titleService,
        title: {},
        whyUs: {}
      }
    },
    methods: {
      getTitle () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.title = item || {}
        }
        this.titleService.first(options)
      },
      getData () {
        const options = new Options()
        options.params = params
        options.success = ({item}) => {
          this.whyUs = item || {}
        }
        this.service.first(options)
      }
    },
    created () {
      this.getTitle()
      this.getData()
    }
  }
</script>

<style scoped>
  .why-us-container {
    --height: 620;
    --title: 140;

    height: calc(var(--height) * 1px);
    /*border: 1px solid red;*/
  }

  .why-us-inner-box {
    width: calc(var(--inner-width) * 1px);
    border: inherit;
  }

  .why-us-title {
    height: calc(var(--title) * 1px);
    line-height: calc(var(--title) * 1px);
    display: block;
    letter-spacing: .5em;
    font-size: 30px;
    font-weight: bold;
    border: inherit;
  }

  .why-us-image {
    width: 100%;
    height: calc((var(--height) - var(--title)) * 1px);
    object-fit: scale-down;
    border: inherit;
  }
</style>
