<template>
  <div class="product-detail-container">
    <div class="wrap">
      <div class="navigate">
        <div class="bread-crumb">
          <span>{{ notificationByLanguage('location') }}: </span>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item>{{ product.division }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ product.category }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ product.title }}</el-breadcrumb-item>
          </el-breadcrumb>
          <span>{{ notificationByLanguage('detail') }}</span>
        </div>
        <div class="return" @click="returnToList">{{ notificationByLanguage('returnTo') }}</div>
      </div>
      <img class="product-image" :src="product.href" @click="showImage(product)" style="cursor: pointer;">
      <table class="table product-structure">
        <thead>
        <tr>
          <th>Position</th>
          <th>Code</th>
          <th>Ref.</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in product.components" :key="item.id">
          <td v-for="(key, index) in item" :key="index">{{ key }}</td>
        </tr>
        </tbody>

      </table>
      <div class="white-space"></div>
      <span class="same-product-title">Complete Torch</span>
      <table class="table same-product">
        <thead>
        <tr>
          <th>Code</th>
          <th>Ref.</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in product.races" :key="item.id">
          <!--        <td v-for="(key, index) in item" :key="index">{{key}}</td>-->
          <!--        <td v-for="key in keys" :key="key" v-if="item[key]">{{item[key]}}</td>-->
          <td>{{ item.title }}</td>
          <td>{{ item.reference }}</td>
          <td>{{ item.description }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LanguageUtils from '@/utils/LanguageUtils'
import LanguageEnum from '@/enumeration/LanguageEnum'

export default {
  name: 'ProductDetail',
  props: {
    product: {
      type: Object,
      default: () => {
      }
    },
    divisionId: {
      type: String,
      default: ''
    },
    categoryId: {
      type: String,
      default: ''
    },
  },
  methods: {
    notificationByLanguage (key) {
      const notifies = {
        location: {
          [LanguageEnum.CHINESE]: '您的位置',
          [LanguageEnum.ENGLISH]: 'Location'
        },
        returnTo: {
          [LanguageEnum.CHINESE]: '返回上一页',
          [LanguageEnum.ENGLISH]: 'Return',
        },
        detail: {
          [LanguageEnum.CHINESE]: '详情页',
          [LanguageEnum.ENGLISH]: 'Detail'
        }
      }
      return notifies[key][LanguageUtils.justifyLanguage()]
    },
    showImage (item) {
      this.$viewerApi({
        images: [{src: item.href || item.thumb, alt: item.title}],
        options: {
          rotatable: false,
          scalable: false,
          initialViewIndex: 0,
          title: image => image['alt']
        }
      })
      // TODO: 设置CSS样式未生效， 暂时强行操作DOM元素进行设置
      document.querySelector('.viewer-title').style.fontSize = '24px'
    },
    returnToList () {
      this.$emit('returnToList')
    }
  }
}
</script>

<style scoped>


table {
  font-size: 16px;
}

.product-detail-container {
  --navigate-height: 30;

  display: flex;
  justify-content: center;
  padding: 4px;
  width: calc((var(--inner-width) - var(--aside-width) - 10) * 1px);
  /*border: 1px solid red;*/
}

.wrap {
  width: 100%;
  padding-left: 20px;
}

.navigate {
  width: inherit;
  height: calc(var(--navigate-height) * 1px);
  line-height: calc(var(--navigate-height) * 1px);
  display: flex;
  justify-content: space-between;
  border: inherit;
}

.bread-crumb {
  height: calc(var(--navigate-height) * 1px);
  line-height: calc(var(--navigate-height) * 1px);
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.bread-crumb > span:first-child {
  display: inline-block;
}

.bread-crumb > span:first-child {
  margin-right: 20px;
}

.bread-crumb > span:last-child {
  margin-left: 20px;
}


::v-deep .el-breadcrumb {
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 2 !important;
}

.return {
  float: right;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.product-image {
  width: inherit;
}

.white-space {
  height: 50px;
}

.table {
  width: inherit;
}

.table tbody:before {
  content: '-';
  display: block;
  line-height: 20px;
  color: transparent;
}

.table td {
  border: 4px solid white;
}


.table th {
  height: 40px;
  font-size: 18px;
  background-color: darkgray;
  border: 4px solid white;
}

.table td::selection,
.table th::selection {
  background-color: var(--main-theme-color) 0%;
  color: var(--main-theme-color);
}


.table tbody tr {
  height: 30px;
}

.table tbody tr:nth-child(odd) {
  background-color: lightgray;
}

.same-product-title {
  display: block;
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  text-align: left;
}

</style>
