<template>
  <div class="main-frame-container" :class="fontFamilyByLanguage">
    <Banner></Banner>
    <Navigation></Navigation>
    <router-view></router-view>
    <quick-menu
      position="bottom-right"
      backgroundColor="#EB6100"
      :menuCount="4"
      :iconClass="icons"
      :menuUrlList="urls"
    >
    </quick-menu>
  </div>
</template>

<script>
  import Banner from '@/views/frame/components/Banner'
  import Navigation from '@/views/frame/components/Navigation'
  import quickMenu from '@/components/quickMenu'
  import LanguageUtils from '@/utils/LanguageUtils'

  export default {
    name: 'MainFrame',
    components: {
      Banner,
      Navigation,
      quickMenu
    },
    computed: {
      fontFamilyByLanguage () {
        return LanguageUtils.justifyLanguage() === 'ENGLISH' ? 'english-font-family' : 'chinese-font-family'
      }
    },
    data () {
      return {
        icons: ['iconfont icon-email', 'iconfont icon-phone', 'iconfont icon-wechat', 'iconfont icon-un-backtotop-o'],
        urls: [
          {isLink: true, url: 'mailto:service2@czinwelt.com'},
          {isLink: true, url: 'tel:13761522114'},
          {isLink: false},
          {isLink: true, url: '#'}]
      }
    }
  }
</script>

<style scoped>
  .english-font-family {
    /*font-family: Hobo Bold !important;*/
    font-family: Arial;
  }

  .chinese-font-family {
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
  }
</style>
